<template>
  <van-nav-bar
  title="关于本系统"
  left-arrow
  @click-left="onGoBack"
  />
  <div class='main'>商品防伪系统</div>


</template>

<script>

export default {
  components: {


  },
	methods: {


  }
  
};
</script>

<style lang="less">
.main{
  text-align: center;
}
</style>
